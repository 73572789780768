<template>
    <div class="titleList">
        <Tabs v-model="activeName" @click="changetab" :swipe-threshold="1">
            <tab :name="item.id" v-for="(item, index) in brandList" :key="index" >
                <template #title>
                    <div class="brandpic acea-row" :class="activeName==item.id?'brandpiccur':''">
            
                        <div class="pic"  style="" v-if="item.id!='-1'" >
                            <img :src="item.wcj_image_logo" />
                        </div>
                        <div class="title" :class="item.id!='-1'?'':'da'" >
                            {{item.brand_name}}
                        </div>
                    </div>
                </template>
            </tab>
        </Tabs>
    </div>
</template>
<script>
import { getBrand } from "@api/tasting";
import { Toast, Tab, Tabs, Rate } from "vant";
import { Image as VanImage } from 'vant';
export default {
    name: "titleList",
    props: {
        activeName: {
            type: Number,
            value: 0
        },
        type: {
            type: Number,
            value: 0
        },
        cate: {
            type: Number,
            value: 0
        },
        index: {
            type: String,
            value: ''
        }
    },
    data: function() {
        return {
            brandList: [],
            type: 0,
        };
    },
    components: {
        VanImage, Tab, Tabs, Rate
    },
    created: function() {
        
    },
    mounted: function() {
        this.getBranddata();
    },
    methods: {
        getBranddata: function() {
            getBrand({
                page: 1,
            }).then(res => {
                this.brandList=res.data;
                if(this.cate){
                     this.brandList.unshift({id:'-1',brand_name:'全部',product:[]})
                }
                //console.log('brand',this.brandList);
                this.$emit("brandListdata", this.brandList);
            })
            .catch(err => {
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
        changetab: function() {
            
            if(this.type==1){
                this.$emit("clickBrandBrand", this.activeName);
            }else{
                var id='';
                //var brand_id = '';
                console.log('brand_id', this.brandList);
                this.brandList.forEach((item, index)=>{
                    if(item.id===this.activeName){
                        if(item.product.length){
                            id=item.product[0].id;
                        }
                    }
                })
                if(this.cate){
                    this.$emit("clickBrandId", this.activeName);
                }else{
                    if(id){
                        this.$emit("clickBrand", id);
                        this.$emit("clickBrandBrand", this.activeName);
                    }else{
                        Toast.fail({
                            message: '该品牌下暂无产品',
                            forbidClick: true,
                        });
                    }
                }
                
                
            }
        },
    }
};
</script>
<style scoped lang="scss">
::v-deep {
    
        .van-tabs__wrap--scrollable{
            .van-tab{padding:0 3px;}
        }
        
        .van-tabs__wrap{
           height: 1.1rem;
        }
        .van-tabs__nav--line{
            background: #f4f4f4!important;
            //height: 66%;
            padding-bottom: 0;
        }
        .van-tabs__line{
            position: absolute;
            //bottom: 0px;
            left: 0;
            //z-index: 10;
            width: 0.2rem;;
            height: 0;
            display: block;
            content: '';
            margin: 0 auto;transform:rotate(45deg);
            background-color: unset;
            border-radius: unset;
            z-index: 1100;
            bottom: 3px;
            display: none;
            
            // top: 100%;
            // left: 50%;
            //margin-left: -0.1rem;
            //background:#000;
        }
        .van-tabs__line::before{
            //position: absolute;
            background:#f4f4f4;
            width: 0.2rem;
            height: 0.2rem;
            display: block;
            content: '';
            margin: 0 auto;transform:rotate(45deg);
             z-index: 1100;
             border: 1px solid #000;
        }
    
}
.titleList{
    //border-bottom:1px solid #eee;
    .brandpic{
        border: 1px solid #e8e8e8;border-radius: 0.1rem;overflow: hidden;height: 1rem;width: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        justify-content:space-between;
        .pic{
            height: 0.9rem; width: 1.5rem;;
            img{width: 100%;height: 100%;}
        }
        .title{
            width: 0.9rem;height: 0.5rem;
                font-size: 8px;
                line-height: 0.22rem;
                color: rgb(156, 156, 156);
        }
        .da{
             font-size: 16px;
             margin: 0 auto;
             line-height: unset;
             line-height: 24px;
        }
        
        
    }
    .brandpiccur{border: 1px solid #4c4744;}
}
</style>