<template>
    <div class="BrandCenter">
        <!-- <BrandList v-on:clickBrand="clickBrand" v-on:brandListdata="brandListdata" :activeName="activeName" ></BrandList> -->
        <div class="brand">
            <TitleList v-on:clickBrandId="clickBrandId" v-on:brandListdata="brandListdata" :activeName="activeName" :cate="cate" ></TitleList>
        </div>
        <div v-for="(item, index) in brandList" :key="index" >
            <div class="branditem">
                <div class="branditem_hd">
                    <div class="picbg">
                        <VanImage width="100%" height="auto" lazy-load :src="require('@assets/images/brand/brandtitbg.png')" />
                    </div>
                    <div class="pic">
                        <VanImage width="100%" height="auto" lazy-load :src="item.logo" />
                    </div>
                    <div class="brand_name">{{item.brand_name}}</div>
                    <div class="bai"></div>
                </div>
            </div>
        <div v-for="(product,indexs) in item.product" :key="indexs" >
            <router-link  class="wine-data-bg" :to="'/Tasting/goods/detail?id=' + product.id">
                <div class="acea-row" style="background: #fff;">
                    <div class="wine-ping">
                        <VanImage width="100%" height="auto" lazy-load :src="product.wcj_parameter_image" />
                    </div>
                    <div class="wine-data">
                        <div class ="title Ellipses1">{{product.store_name}}</div>
                        <div class="wineData">
                            <p>
                                <span>国<span class="kuan1"></span>家：</span>
                                <span>{{product.countries.country}}</span>
                            </p>
                            <p>
                                <span>产<span class="kuan1"></span>区：</span>
                                <span>{{product.countries.brand_name}}</span>
                            </p>
                            <p>
                                <span>年<span class="kuan1"></span>份：</span>
                                <span>{{product.year.brand_name}}</span>
                            </p>
                            <p>
                                <span>级<span class="kuan1"></span>别：</span>
                                <span>{{product.winelever.brand_name}}</span>
                            </p>
                            <p  class="acea-row" >
                                <span>获<span class="kuan1"></span>奖：</span>
                                <span class="acea-row" >
                                    <div v-for="(prize,ins) in product.prize" :key="ins">
                                        <VanImage width="0.4rem" height="auto" lazy-load :src="prize.image" />
                                    </div>
                                </span>
                            </p>
                            <p  style=" margin-bottom: 0.12rem;">
                                <span>标配包装：</span>
                                <span>{{product.pack.title}}</span>
                            </p>
                            <p>
                                <span>推荐理由：</span>
                                <span>{{product.reason}}</span>
                            </p>
                            <p >
                                <span>零<span class="kuan3"></span>售<span class="kuan3"></span>价：</span>
                                <span>¥<span class="num" >{{product.sc_price}}</span><span>/{{product.unit_name}}</span></span>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- <div class="store_price" v-if="getStauts(product.is_store_price,product.is_user_price,product.store_price,store)">
                    <div class="acea-row"> 
                        <div class="box"></div>
                        <div class="acea-row row-middle" >
                            <span style="font-size: 0.3rem;">招商代理价：</span>
                            <span class="font-color">¥<span class="num" style="font-weight: 600;font-size: 0.35rem;">{{product.store_price}}</span><span class="ping">/{{product.unit_name}}</span></span>
                        </div>  
                    </div>      
                 </div> -->
                <!-- <div class="canshu">
                    <div class="item" v-for="(canshu, indexc) in product.mao" :key="indexc">
                        <div class="tit">{{canshu.name}}</div>
                        <div  v-for="(sonitem, sonindex) in canshu.list" :key="sonindex"><span>{{sonitem}}</span></div>
                    </div>
                </div> -->
                </router-link>
                <div class="PackList" v-if="product.packList.length">
                    <Tabs class=""   @change="onChange" :swipe-threshold="i" >
                        <tab class="tab " :name="indexs" v-for="(item, indexs) in product.packList" :key="indexs">
                            <template #title >
                                <div class="Thumb">
                                    <div class="Thumbpic">
                                        <VanImage  width="1.2rem"  lazy-load :src="item.image" />
                                    </div>
                                    <div class="Thumbname Ellipses1">
                                        {{item.short_title}}
                                    </div>
                                </div>
                            </template>
                        </tab>
                    </Tabs>
                </div>
                <div class="PackList" style="background-color: #ddd;padding: 0.1rem;text-align: center;color: #fff;height: 2.2rem;" v-else>
                    <div>暂无包装</div>
                </div>
            </div>
        </div>
        <!-- <div class="Loading" v-if="loading">
                <Loading size="24px">加载中...</Loading>
            </div>
         <div class="Loading" v-if="finished">没有更多了~</div> -->
         <div style="height: 1.2rem;"></div>
    </div>
</template>

<script>
    import { swiper, swiperSlide } from "vue-awesome-swiper";
    import { getBrand, getBrandList,getBrandProduct } from "@api/tasting";
    import { Toast, Tabs,Tab } from "vant";
    import { Image as VanImage } from 'vant';
    import Backtop from "./components/Backtop";
    import Footer from "./components/Footer";
    import BrandList from "./components/brandList";
    import TitleList from "./components/titleList";
    import Header from "./components/Header";
    let vm = null;
    export default {
        name: "BrandCenter",
        data: function() {
            return {
                id:0,
                page:1,
                loading:false,
                finished:false,
                cate:1,
                store:0,
                activeName: 0,
                // brandList: [],
                // brandList2: [],
                // wcj_images_line: '',
                // brandList3: [],

                brandList: [],

                active: 0,
                swiperOptionHd: {
                    loop: false, //是否循环
                    loopedSlides: 5, // looped slides should be the same
                },
                swiperOptionTop: {
                    loop: false, //是否循环
                    loopedSlides: 5, // looped slides should be the same
                    effect : 'coverflow',
                    slidesPerView: 5,
                    centeredSlides: true,
                    spaceBetween: -140,
                    coverflowEffect: {
                        rotate: 0,
                        stretch: 0,
                        depth: 400,
                        modifier: 2,
                        slideShadows : false
                    },
                },
                realIndex: 0,
                swiperOptionThumbs: {
                    loop: false,
                    loopedSlides: 5, // looped slides should be the same
                    spaceBetween: 10,
                    centeredSlides: true,
                    slidesPerView: 'auto',
                    touchRatio: 0.2,
                    slideToClickedSlide: true,
                    on: {
                        slideChange: function(){
                            vm.getrealIndex(this.realIndex);
                        },
                    },
                },
            };
        },
        components: {
            BrandList,
            swiper,
            swiperSlide,
            VanImage,
            Backtop, Footer,Tabs,Tab,TitleList
        },
        created() {
            vm = this;
            //this.activeName=this.$route.query.id;
            this.$store.state.app.storeid = this.$route.query.store_id;
            
        },
        mounted: function() {
            //this.getBrandListdata();
            this.getBrandProducts();
            var that = this;
            // window.addEventListener("scroll", function(event) {
            //     var scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            //     if(document.documentElement.scrollHeight == document.documentElement.clientHeight + scrollTop) {
            //         that.Loadmore();
            //     }
            // });
        },
        methods: {
            getStauts:function(is_store_price,is_user_price,store_price,store){
                if(store&&store_price!='0.00')return true;
                return is_store_price==0&&is_user_price&&store_price!='0.00';
            },
            goPages: function(url) {
                this.$router.push({ path: url });
            },
            clickBrandId: function (id) {
                //this.$router.push({ path: '/Tasting/BrandCenters?id=' + id });
                console.log(id);
                this.activeName=id;
                if(id=='-1'){
                    this.getBrandProductq();
                }else{
                    this.getBrandProducts(id);
                }
                
            },
            brandListdata: function (data) {
                //console.log('id',data[0].id);
                this.activeName=data[0].id;
            },
            getrealIndex: function(realIndex) {
                this.realIndex=realIndex;
                this.wcj_images_line=this.brandList3[realIndex].wcj_images_line;
            },
            product: function(index, sonindex) {
                this.brandList2[index].pid=this.brandList2[index].product[sonindex].id;
                this.brandList2[index].wcj_image=this.brandList2[index].product[sonindex].wcj_images_exh;
            },
            Loadmore: function() {
                if(this.loading) return;
                if(this.finished) return;
                this.getBrandProduct();
            },
            getBrandProducts:function(id=0){
                    Toast.loading({
                        duration: 0,
                        message: '加载中...',
                        forbidClick: true,
                    });
                    getBrandProduct({
                        id:id,
                    }).then(res=>{
                        Toast.clear();
                        var brandList = res.data.brand
                        this.brandList = brandList;
                    }).catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            getBrandProductq:function(){
                    Toast.loading({
                        duration: 0,
                        message: '加载中...',
                        forbidClick: true,
                    });
                    getBrandProduct().then(res=>{
                        Toast.clear();
                        var brandList = res.data.brand
                        this.brandList = brandList;
                    }).catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            getBrandProduct:function(){
                    this.loading = true;
                    Toast.loading({
                        duration: 0,
                        message: '加载中...',
                        forbidClick: true,
                    });
                    getBrandProduct({
                        //page:this.page,
                        //limit:1,
                    }).then(res=>{
                        Toast.clear();
                        
                        var brandList = res.data.brand
                        this.store = res.data.store
                        brandList.forEach(function(brand){
                            brand.product.forEach(function(pro){
                                var mao=pro.mao;
                                mao.forEach(function(item) {
                                    var reg=/\\n/g;
                                    item.value2=item.value.replace(reg,'/');
                                    item.list=item.value2.split('/');
                                })
                            })
                            
                        })
                        brandList.forEach((item)=>{
                            this.brandList.push(item);
                        })
                        this.loading = false;
                        this.page++;
                        if(brandList.length<1){
                            this.finished = true;
                        }
                        //this.brandList = brandList;
                        console.log(this.brandList);
                    }).catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            getBrandListdata: function() {
                Toast.loading({
                    duration: 0,
                    message: '加载中...',
                    forbidClick: true,
                });
                getBrandList({
                    page: 1,
                }).then(res => {
                    Toast.clear();
                    let datalist=res.data;
                    datalist.forEach((item, index)=>{
                        if(item.product.length>0){
                            item.pid=item.product[0].id;
                            item.wcj_image=item.product[0].wcj_images_exh;
                        }
                    })
                    this.brandList2=datalist;
                    // if(res.data[0]){
                    //     this.brandList3=res.data[0].product;
                    //     if(this.brandList3[0]){
                    //         this.wcj_images_line=this.brandList3[0].wcj_images_line;
                    //     }
                    // }
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
        },
        watch:{
            brandList3: function() {
                this.$nextTick(() => {
                    const swiperTop = this.$refs.swiperTop.swiper
                    const swiperThumbs = this.$refs.swiperThumbs.swiper
                    swiperTop.controller.control = swiperThumbs
                    swiperThumbs.controller.control = swiperTop
                })
            },
            deep: true,
        }
    }
</script>

<style scoped lang="scss">
.Loading{text-align: center;padding:0.2rem;font-size:0.28rem;color:#999;margin-bottom: 1.5rem;}
::v-deep {
    .brandList{
        .van-tab{padding:0.3rem 0.1rem;}
        .van-tabs__wrap {
            height: 1.2rem;
        }
        .van-tabs__line{background:none;}
        // .van-tabs__line::before{
        //     background:#f4f4f4;
        //     border-left: 1px solid #b6b6b6;
        //     border-top: 1px solid #b6b6b6;
        //     width: 0.2rem;
        //     height: 0.2rem;
        //     display: block;
        //     content: '';
        //     margin: 0 auto;transform: rotate(45deg);
        // }
    }
}
    .BrandCenter{
        .brand{
            width: 6.5rem;
            background: #f4f4f4;
            position: fixed;
            z-index: 1100;
            top:0.02rem
        }
    }
    .branditem{
        background:#fff;margin-top: 0.3rem;padding-top: 0.1rem;
        .branditem_hd{
            height:1.2rem;background:#f4f4f4;border-top:1px solid #e8e8e8;position:relative;
            margin-bottom: 0.8rem;
            .pic{position:absolute;left: 0.2rem;width:1.82rem;height:1.6rem;border:1px solid #e8e8e8;overflow:hidden;border-radius:0.1rem;z-index:2;}
            .goodslist{
                position:absolute;right: 0;overflow-x: scroll;max-width:5.4rem;white-space: nowrap;z-index:2;
                .dpic{margin: 0.2rem 0.2rem 0;width: 0.4rem;display: inline-block;}
                .curdpic{margin: 0;width: 0.9rem;height: 1.7rem;border: 1px solid #cecece;padding: 0 0.2rem 0;display: inline-block;background: #f5f5f5;}
            }
            .picbg{position:absolute;left: 1.84rem;right: 0;z-index:1;}
            .brand_name{
                    position: absolute;
                    left: 2.4rem;
                    right: 0;
                    top: 1rem;
                    z-index: 1;
                    font-size: 0.38rem;
                    font-weight: 600;
            }
            .bai{
                    height: 0.4rem;
                    background-color: #fff;
                    margin-top: 1.5rem;
                    border-bottom: 1px solid;
            }
        }
        .branditem_bd{
            position:relative;min-height: 1rem;
            .branditem_bd_f{position:absolute;right: 0;left:0;bottom:0;text-align: center;color: #fff;line-height:0.8rem;}
        }
    }
    .wine-data-bg{
        background-color: #fff;
        border-bottom: 1px solid;
        margin: 0.2rem 0;
        .store_price{
            background: #fedee1;
            padding: 0.26rem 0;
            .box{
                width: 2.6rem;
            }
        }
        .wine-ping{
            width: 2.6rem;
        }
        .wine-data{
                .title{    
                    font-weight: 600;
                    font-size: 0.3rem;
                    padding: 0.1rem 0;
                    width: 4.8rem;
                }
                .wineData{
                    width: 4.8rem;
                    margin-bottom: 0.18rem;
                    .kuan1{
                        width: 0.55rem;
                        display: inline-block;
                    }
                    .kuan2{
                        width: 0.2rem;
                        display: inline-block;
                    }
                    .kuan3{
                        width: 0.14rem;
                        display: inline-block;
                    }
                }
                .scPrice{
                    color: #4a4a4a;
                    font-size: 0.3rem;
                }
                .scPrices{
                    color: #777;
                    font-weight: 600;
                    font-size: 0.35rem;
                }

        }
        .canshu{
            overflow-x: auto;background:#fff;padding:0.2rem 0;margin-top:0.2rem;
            border-bottom: 1px solid;
            margin-bottom: 0.2rem;
            .item {
                border-left: 1px solid #eee;padding: 0 0.3rem;white-space: nowrap;
                display: table-cell;
                span{font-size:0.2rem;color:#999;}
            }
            .item:first-child {border-left: none;}
        }
    }

    .num{
      // font-family: Ariel;
       font-size:0.3rem
    }
    .ping{
        font-size:0.3rem
    }
::v-deep {
    
    .PackList{
        .van-tab{background-color: #ddd;    padding: 0.1rem;}
        .van-tabs__nav{
            padding: 0;
        }
        .van-tabs__wrap {
            height: 2.5rem;margin:0.1rem 0;
                overflow-x: auto;
            
        }
        .van-tab__text{
            .Thumb{
                background: #fff;
                text-align: center;
                border-radius: 0.1rem;
                padding: 0.1rem 0.2rem;
            }
        }
        .van-tabs__line{display:none;}
    
    }
    
}
.PackList{
    background: #fff;
    width: 100%;
    overflow: hidden;
    .tit{
        color: #545454;
        font-size: 0.35rem;
        padding: 0.6rem 0.3rem;
        background: #e1e1e1;
        span{
            padding-left: 0.1rem;
        }
    }
    .packplay{
        text-align: center;
        .box{
            padding: 0.54rem 0 0rem 0;
            height: 8rem;
            .en_title{
                color: #464646;
                font-size: 0.55rem;
                line-height: 0.5rem;
                margin-bottom: 0.1rem;
            }
            .title{
                font-size: 0.3rem;
                //padding: 0rem 0rem 0.13rem 0rem;
                color: #464646;
                font-weight: 600;
                margin-bottom: 0.1rem;
            }
            .introduce{
                color: #ccc;
                font-weight: normal;
                padding-top: 0.03rem;
            }
            .iamges{
                padding: 0 1rem;
                // height: 4rem;
            }
        }
    }
    .Thumbpic{
        border-radius: 0.1rem;overflow: hidden;height: 1.2rem;width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        img{width: auto;height: 100%;}
    }
    .Thumbname{text-align: center;font-size: 0.2rem;line-height: 0.5rem;width: 2rem;}
} 
</style>