<template>
        
        <div class="Headercon" style="    padding-bottom: 1.2rem;">
        <div class="hdmenu acea-row row-middle" style="height: 1.2rem;z-index: 1009;">
            <!-- <router-link :to="item.wcj_url" class="item" v-for="(item, index) in TabsList" :key="index">
                <i v-if="cur==index"></i>
                <div :class="cur==index?'hdmenuacur':'hdmenua'" >{{ item.menu_name }}</div>
            </router-link> -->
            
            
            <div class="Header" style="background-color:unset;left:unset;top:unset;border-bottom:unset">
                <div class="hdnav">
                    <div class="item menuicon" @click="changemenu">
                        <span class="iconfont" :class="menuicon?'iconguanbi1':'iconcaidan-'"></span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="brand">
            <TitleList v-on:clickBrand="clickBrand" v-on:brandListdata="brandListdata" :activeName="activeName" ></titleList>
        </div> -->
        <Popup v-model="menuicon" :overlay="false" position="top" class="drop_menu">
            <div v-for="(item, index) in TabsList" :key="index">
                <div v-if="item.is_open==0">
                    <Cell :title="item.menu_name" is-link :to="item.wcj_url" @click="changemenu">
                        <template #right-icon>
                            <Icon name="" class="iconfont iconjia1" />
                        </template>
                    </Cell>
                </div>
                <div v-if="item.is_open==1">
                    <Cell :title="item.menu_name" is-link @click="changebrand" v-if="item.is_open==1">
                        <template #right-icon>
                            <Icon name="" class="iconfont" :class="activebrand?'iconjian':'iconjia1'" />
                        </template>
                    </Cell>
                    <div class="drop_menu2" v-if="activebrand" v-for="(item, index) in CollapseItem" :key="index">
                        <Cell :title="item.brand_name" is-link @click="changebrand2(index, item.id)">
                            <template #title>
                                {{item.brand_name}}{{item.countries_num>0?'（' + item.countries_num + '个国家）':''}}
                            </template>
                            <template #right-icon>
                                <Icon name="" class="iconfont" :class="activeName2==index?'iconjian':'iconjia1'" />
                            </template>
                        </Cell>
                        <div class="goodsListmenu" v-if="activeName2==index">
                            <div class="goodsList" v-if="item.goods.length">
                                <div class="goodsitem" v-for="(sonitem, sonindex) in item.goods" :key="sonindex" @click="goPages('/Tasting/goods/detail?id=' + sonitem.id)">
                                    <div class="pic">
                                        <img :src="sonitem.wcj_images"/>
                                    </div>
                                    <p class="Ellipses1">{{sonitem.abbreviation_name}}</p>
                                </div>
                            </div>
                            <p class="nodata" v-else>暂无数据</p>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
    </div>
</template>

<script>
    import wx from "weixin-js-sdk";
    import { getmenu } from "@api/tasting";
    import TitleList from "./titleList";
    import { Icon, Cell, Popup, Collapse, CollapseItem, Toast } from "vant";
    export default {
        name: "Header",
        data: function() {
            return {
                cur: 1000,
                menuicon: false,
                activeName: 1000,
                activeName2: 1000,
                activebrand: true,
                CollapseItem: [],

                TabsList: [],
                mapUrl:'',
                isdingwei:false,

                brandList: [],
            };
        },
        components: {
            Icon, Cell, Popup, Collapse, CollapseItem,TitleList
        },
        created: function() {
            this.cur=this.$route.meta.cur;
        },
        mounted: function() {
            let that = this;
            getmenu().then(res => {
                that.TabsList=res.data;
                var pid='';
                that.TabsList.forEach(function(item) {
                    if (item.is_open === 1) {
                        pid=item.id;
                    }
                });
                getmenu({
                    id:pid,
                    level:2,
                }).then(res => {
                    that.CollapseItem=res.data;
                    that.CollapseItem.forEach(function(item) {
                        item.goods=[];
                    });
                })
            })
            
        },
        methods: {
            goPages: function(e) {
                this.changemenu();
                this.$router.push({ path: e });
            },
            changemenu: function() {
                this.menuicon=!this.menuicon;
            },
            changebrand: function() {
                this.activeName=1000;
                this.activebrand=!this.activebrand;
            },
            changebrand2: function(index, id) {
                if(this.activeName2==index){
                    this.activeName2=1000;
                }else {
                    if(!this.CollapseItem[index].goods.length){
                        Toast.loading({
                            duration: 0,
                            message: '加载中...',
                            forbidClick: true,
                        });
                        getmenu({
                            id:id,
                            level:3,
                        }).then(res => {
                            Toast.clear();
                            this.CollapseItem[index].goods=res.data;
                            this.activeName2=index;
                        })
                        .catch(err => {
                            Toast.fail({
                                message: err.msg,
                                forbidClick: true,
                            });
                        });
                    }else {
                        this.activeName2=index;
                    }
                }
            },
            clicknavigateTo: function(url) {
                wx.miniProgram.navigateTo({url: url})
            },
            clickswitchTab: function(url) {
                wx.miniProgram.switchTab({url: url})
            },

            clickBrand:function(id){
                this.$emit("clickBrand", id);
            }
        },
        watch:{
            $route(to,from){
                this.cur=to.meta.cur;
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep {
        .van-collapse-item__content {
            padding: 0.2rem 0 0.1rem 0.2rem;
        }
    }

    .Headercon{
        // .brand{
        //     width: 6.5rem;
        //     background: #f4f4f4;
        //     position: fixed;
        //     z-index: 1100;
        //     top:0.02rem
        // }
        .Header{
            .iconfont{
                    padding: 0.13rem;
                    background: #fff;
                    border-radius: 0.08rem;
                    border: 1px solid #ccc;
            }
            background-color: #fff;
            display: -webkit-box;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            height: 1rem;
            -webkit-box-pack: justify;justify-content: space-between;border-bottom: 1px solid #eee;position: fixed;top: 0;left: 0;right: 0;z-index: 1009;
            .city-entry {
                padding-left: 0.2rem;
                font-size: 0.28rem;
                color: #666;
                display: -webkit-box;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                .city-name {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 1.2rem;
                }
                .city-entry-arrow {
                    width: 0;
                    height: 0;
                    border: 0.1rem solid #b0b0b0;
                    border-left-color: transparent;
                    border-right-color: transparent;
                    border-bottom-color: transparent;
                    display: inline-block;
                    margin-left: 0.1rem;
                    margin-top: 0.1rem;
                }
                .logo_pic{
                    display: -webkit-box;display: flex;position: relative;justify-content: space-around;padding-left: 0.2rem;height: 0.6rem;
                    img{height: 100%;}
                }
            }
            .hdnav{
                padding: 0 0.1rem;display: flex;
                .item{
                    margin: 0 0.16rem;font-size: 0.3rem;line-height: 1rem;
                }
                .menuicon{
                    font-size: 0.4rem;
                    //.iconguanbi1{font-size: 0.3rem;}
                }
            }
        }
        .hdmenu{
            position: fixed;
            //top: 1rem;
            left: 0;right: 0;z-index: 100;background: #f4f4f4;height: 0.8rem;display: flex;border-bottom: 1px solid #eee;
            a{margin: 0 0.2rem;position: relative;height: 0.8rem;line-height: 0.8rem;}
            a i{position: absolute;content: '';display: block;bottom: -0.1rem;left: 50%;margin-left: -0.1rem;
                background: #f4f4f4;
                width:0.2rem;
                height:0.2rem;
                transform:rotate(45deg);
                border-right:1px solid #eee;
                border-bottom:1px solid #eee;}
            .hdmenuacur{color:#000;}
            .hdmenua{color:#666;}
        }
        .van-popup--top{bottom: 0;z-index: 1008!important;padding-top: 1rem;}
        .drop_menu{
            i{margin-top: 0.1rem;}
        }
        .drop_menu2{
            padding-left: 0.6rem;
            .goodsList{
                overflow: scroll;white-space: nowrap;height: 3.1rem;
                .goodsitem{
                    display: inline-block;margin-right: 0;width: 1.6rem;
                    .pic{
                        border: 1px solid #eee;width: 1.4rem;height: 2.4rem;overflow: hidden;display: -webkit-flex;
                        display: flex;
                        -webkit-align-items: center;
                        align-items: center;
                        -webkit-justify-content: center;
                        justify-content: center;
                        padding: 0.1rem 0;
                        img{height: 100%;}
                    }
                    p{line-height: 0.5rem;text-align: center;font-size: 0.2rem;}
                }
            }
            .nodata{color: #999;padding: 0.2rem 0.5rem;}
        }
    }
</style>